<template>
  <div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-3">
                <label>Start Date</label><br>
                <date-picker v-model="filter.start_date" value-type="YYYY-MM-DD" :placeholder="filter.start_date" @change="downloadReport('view')"></date-picker>
            </div>
            <div class="col-md-3">
                <label>End Date</label><br>
                <date-picker v-model="filter.end_date" value-type="YYYY-MM-DD" :placeholder="filter.end_date" @change="downloadReport('view')"></date-picker>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-3 float-right">
                <b-dropdown
                    variant="info"
                    class="mt-2 float-right"
                >
                    <template #button-content>
                      Download Report
                    </template>
                    <b-dropdown-item @click="downloadReport('excel')">
                      Excel
                    </b-dropdown-item>
                    <b-dropdown-item @click="downloadReport('pdf')">
                      PDF
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="col-md-12 mt-1 report">
                <div v-if="isLoading" class="text-center">
                    <b-spinner
                        class="mb-1"
                        variant="primary"
                    /><br>
                    Loading...
                </div>
                <div class="table-responsive" v-html="contentHtml" v-if="!isLoading && contentHtml != null"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import FileSaver from 'file-saver'
import DatePicker from 'vue2-datepicker';
import axios from "axios";
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import 'vue2-datepicker/index.css';

export default {
    title () {
        return `Recap Clearing Report`
    },
    components: {
        DatePicker,
        BDropdown,
        BDropdownItem,
        BSpinner
    },
    watch: {
        filter: {
            handler: function() {
                this.downloadReport('view')
            },
            deep: true
        }
    },
    setup(props) {
        return {
            checkPermission,
            successNotification,
            errorNotification,
        }
    },
    data() {
        return {
            filter: {
                start_date: '',
                end_date: '',
                filter: 'excel'
            },
            isLoading: false,
            contentHtml: null,
            requests: [],
            request: null,
        }
    },
    mounted(){
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        this.filter.start_date = date
        this.filter.end_date = date

        this.downloadReport('view')
    },
    methods: {
        cancel() {
            this.request.cancel();
            this.clearOldRequest("Cancelled");
        },
        downloadReport(type = 'view') {
            if (this.request) this.cancel();
            
            var context = this
            context.filter.report_type = type

            const axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel, msg: "Loading..." };

            if (type == 'view') {
                context.isLoading = true
            }

            this.$http.get('/admin/report/recap-clearing', {
                responseType: type == 'view' ? '' : 'blob',
                params: context.filter,
                cancelToken: axiosSource.token
            }).then(response => {

                this.clearOldRequest("Success");
                if (type == 'view') {
                    context.contentHtml = response.data.data.html
                    context.isLoading = true
                } else {

                    var timestamp = Math.floor(Date.now() / 1000);
                    if (type == 'excel') {
                        var fileName = 'recap-clearing-' + timestamp  + '.xlsx';
                    }

                    if (type == 'pdf') {
                        var fileName = 'recap-clearing-' + timestamp  + '.pdf';
                    }

                    FileSaver.saveAs(response.data, fileName);

                }

                context.isLoading = false;
            }).catch(error => {
                context.isLoading = false;
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)   
                }
            })
        },
        logResponseErrors(err) {
            if (axios.isCancel(err)) {
                console.log("Request cancelled");
            }
        },
        clearOldRequest(msg) {
            this.request.msg = msg;
            this.requests.push(this.request);
            this.request = null;
        }
    },
}
</script>

